import React from "react";

import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/reisedurchfall-infos-und-fakten"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div>
                          <div id="search_form">
                            <input
                              type="text"
                              name="search_block_form"
                              id="searchword"
                              maxLength="128"
                            />
                            <input
                              type="submit"
                              name="suchen"
                              id="searchsubmit"
                              defaultValue
                            />
                            <input
                              type="hidden"
                              name="form_token"
                              defaultValue="fpRQ2dP7NgBmLR_3_abvt9HZCsr1ssctdnKOS8-vJyo"
                            />
                            <input
                              type="hidden"
                              name="form_id"
                              defaultValue="search_block_form"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="meta-text">
                IMODIUM®.&nbsp;Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/expertenrat/">Expertenrat</a>
                  <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p9">
                    Kontakt
                  </a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/wie-wirkt-imodium-akut/">Wie wirkt IMODIUM®?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Diagnose Reizdarm</a>
                    </li>
                    <li className="menu-627 active-trail">
                      <a
                        href="/reisedurchfall-vorbeugen/"
                        className="active-trail"
                      >
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-auf-reisen"
                  className="block block-menu"
                >
                  <h2>Durchfall auf Reisen</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/reisedurchfall-vorbeugen/" title>
                          Vorbeugung von Reisedurchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/reiseapotheke/" title>
                          Reiseapotheke
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/reisedurchfall-risikofaktoren/" title>
                          Reisedurchfall Risikofaktoren
                        </a>
                      </li>
                      <li className="last leaf active-trail">
                        <a
                          href="/reisedurchfall-infos-und-fakten/"
                          title
                          className="active-trail active"
                        >
                          Infos &amp; Fakten zu Reisedurchfall
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-15" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/120228_imodium_anlan_07.jpg) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h3
                        style={{
                          "font-size": "30px",
                          "line-height": "26px",
                          margin: "20px 0pt 10px",
                          "font-family": "'Open Sans',sans-serif"
                        }}
                      >
                        IMODIUM®
                        <br />
                        akut Schmelz-
                        <br />
                        tabletten
                      </h3>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/pictures/teaser_akutLingual1_1.jpg"
                          style={{
                            width: "174px",
                            height: "106px"
                          }}
                        />
                        <br />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Perfekt für unterwegs! Schmilzt in Sekunden auf der
                        Zunge und kann ohne Wasser eingenommen werden.
                        <br />
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a
                            className="georgia arrow_01"
                            href="/imodium-akut-schmelztabletten/"
                          >
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Infos &amp; Fakten zu Reisedurchfall
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="flash_container">
                      <div className="field field-name-field-teaser-stuff field-type-text-long field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            <p>
                              <img
                                alt
                                src="/sites/default/files/header_visuals_695x286_reise.jpg"
                                style={{
                                  width: "695px",
                                  height: "286px"
                                }}
                              />
                            </p>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="std-site-alternative">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Behandlung
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            Sollten Sie dennoch auf Reisen von einer
                            Durchfallattacke gequält werden, ist guter Rat
                            teuer:{" "}
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div
                              className="field-item even"
                              property="content:encoded"
                            >
                              <h2>Durchfall-Symptome:</h2>
                              <p>
                                Reisedurchfall unterscheidet sich symptomatisch
                                nicht von „gewöhnlichem“ Durchfall und ist in
                                der Regel leicht zu erkennen. Er äußert sich
                                durch:
                              </p>
                              <ul
                                style={{
                                  "margin-left": "20px"
                                }}
                              >
                                <li>
                                  Mehrmals täglich auftretende Stuhlentleerungen
                                </li>
                                <li>Häufig assoziiert mit Übelkeit</li>
                                <li>
                                  Erbrechen und krampfartige Bauchbeschwerden
                                </li>
                                <li>Je nach Erreger auch Fieber</li>
                              </ul>
                              <p>
                                Falls Sie akuter Durchfall erwischt hat, können
                                Sie Ihren Körper mit den richtigen Mitteln und
                                der richtigen Ernährung bei der Genesung
                                unterstützen. Um mehr zu erfahren, klicken Sie
                                hier.
                              </p>
                              <h3>&nbsp;</h3>
                              <h2>Reisedurchfall Ursachen:</h2>
                              <p>
                                Reisedurchfall kann viele Ursachen haben. Eine Möglichkeit sind Magen-Darm-Infektionen mit verschiedenen Auslösern:<br />- Viren (z.B. Noro- oder Rotaviren)<br />- Bakterien (z.B. Kolibakterien, Shigellen oder Salmonellen)<br />- Parasiten (z.B. Amöben, Giardien / Lamblien oder Kryptosporidien)<br />Übertragen werden diese häufig durch unreines Trinkwasser, ungekochtes oder ungewaschenes Gemüse, Salat oder Obst, rohes Fleisch. Aber es kommen auf Reisen auch weitere Auslöser für Durchfall in Frage:
                              </p>
                              <ul
                                style={{
                                  "margin-left": "20px"
                                }}
                              >
                                <li>
                                  Ungewohnte Klimaverhältnisse und Lebensmittel
                                </li>
                                <li>Stress und Nervosität</li>
                                <li>Geschwächtes Immunsystem</li>
                              </ul>
                              <h3>&nbsp;</h3>
                              <h2>Reisedurchfall Dauer und Verlauf:</h2>
                              <p>
                                In den meisten Fällen dauern die Durchfälle etwa
                                drei bis vier Tage an. Je nachdem, welcher
                                Krankheitserreger für den Reisedurchfall
                                verantwortlich ist, kann sich in seltenen Fällen
                                eine ernstere Durchfallerkrankung (z.B.
                                bakterielle Ruhr, Amöbenruhr, Cholera)
                                entwickeln. Deshalb ist es wichtig, dass Sie
                                Ihre Beschwerden dokumentieren und ernst nehmen.
                                Besonders auf Reisen stellt sich die Frage, ab
                                wann es notwendig ist, einen Arzt zu
                                konsultieren. In jedem Fall sollte ein Arzt
                                aufgesucht werden, wenn:
                              </p>
                              <ul
                                style={{
                                  "margin-left": "20px"
                                }}
                              >
                                <li>
                                  der Durchfall länger als 48 Stunden anhält,
                                </li>
                                <li>der Stuhlgang blutig ist,</li>
                                <li>
                                  der Durchfall durch Fieber (über 38 Grad
                                  Celsius) begleitet wird.
                                </li>
                              </ul>
                              <p>
                                Falls Sie während eines längeren Urlaubs
                                wiederholt unter akutem Durchfall leiden, ist es
                                empfehlenswert ein Tagebuch zu führen. So
                                besteht die Chance, den Auslöser zu
                                identifizieren und zu eliminieren, damit Sie den
                                Rest Ihres Urlaubs genießen können.
                              </p>
                              <p>&nbsp;</p>
                              <h2>Durchfall nach der Reise:</h2>
                              <p>
                                Wenn Sie nach der Rückkehr von Ihrer Reise
                                Durchfall bekommen, suchen Sie einen Arzt auf,
                                insbesondere wenn Sie in einem tropischen Land
                                mit erhöhten Reisedurchfallrisiko waren. Dieser
                                kann feststellen, ob der Durchfall von
                                Krankheiten oder Parasiten aus dem Reiseland
                                nachträglich ausgelöst wurde und gegebenenfalls
                                die passende Behandlung einleiten.
                              </p>
                              <p>
                                Falls Sie während Ihrer Reise von akutem
                                Durchfall heimgesucht wurden und sich nach Ihrer
                                Rückkehr weiterhin unwohl fühlen oder einfach
                                nur auf Nummer sichergehen wollen, suchen Sie
                                ebenfalls Ihren Arzt auf.
                              </p>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div id="footer">
          <div className="wrap">
            <div className="sitemap">
              <div className="col1">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/wie-wirkt-imodium-akut/" title>
                      Wie wirkt IMODIUM®?
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/loperamid/" title>
                      Der Wirkstoff: Loperamid
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last expanded">
                    <a href="/imodium-produkte/" title>
                      Produktübersicht
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/imodium-akut-schmelztabletten/" title>
                          IMODIUM® akut Schmelztabletten
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/imodium-kapseln/" title>
                          IMODIUM® Kapseln
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="col2">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/fakten-uber-durchfall/" title>
                      Fakten über Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/tipps-bei-durchfall/" title>
                      Tipps bei Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first last leaf">
                        <a href="/tipps-fuer-den-winter/" title>
                          Tipps für den Winter
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/ursachen-von-durchfall/" title>
                      Ursachen von Durchfall
                    </a>
                  </li>
                  <li className="expanded">
                    <a href="/richtig-essen/" title>
                      Gesunde Essgewohnheiten
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/nahrungsmittelallergie/" title>
                          Nahrungsmittelallergien und -unverträglichkeiten
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/lebensmittelvergiftung/" title>
                          Lebensmittelvergiftung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/rezepte/" title>
                          Gesunde Rezepte
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="expanded">
                    <a href="/stress-durchfall/" title>
                      Stress und Durchfall
                    </a>
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/hohe-arbeitsbelastung/" title>
                          Hohe Arbeitsbelastung
                        </a>
                      </li>
                      <li className="last leaf">
                        <a href="/prufungssituationen/" title>
                          Prüfungssituationen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="leaf">
                    <a href="/chronischer-durchfall/" title>
                      Chronischer Durchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/gastroenteritis/" title>
                      Magen-Darm-Grippe
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/norovirus/" title>
                      Norovirus
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/durchfall-wahrend-ihrer-periode/" title>
                      Durchfall während Ihrer Periode
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col3">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/diagnose-reizdarm/" title>
                      Diagnose Reizdarm (IBS)
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/behandlung-eines-reizdarms/" title>
                      Behandlung eines Reizdarms
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/reizdarm-ernahrung/" title>
                      Ernährung bei Reizdarm
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/reisedurchfall-vorbeugen/" title>
                      Vorbeugung von Reisedurchfall
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reiseapotheke/" title>
                      Reiseapotheke
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/reisedurchfall-risikofaktoren/" title>
                      Reisedurchfall Risikofaktoren
                    </a>
                  </li>
                  <li className="last leaf active-trail">
                    <a
                      href="/reisedurchfall-infos-und-fakten/"
                      title
                      className="active-trail active"
                    >
                      Infos &amp; Fakten zu Reisedurchfall
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="col4">
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/durchfall-bei-kindern/" title>
                      Durchfall bei Kindern
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/infomaterial/" title>
                      Infomaterial
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/faq-page/" title>
                      FAQ
                    </a>
                  </li>
                </ul>{" "}
                <ul className="menu">
                  <li className="first last leaf">
                    <a href="/glossar/" title>
                      Glossar
                    </a>
                  </li>
                </ul>{" "}
              </div>
              <div className="clear" />
            </div>
            <div className="subnavi">
              <div className="region region-metanavi">
                <ul className="menu">
                  <li className="first leaf">
                    <a href="/impressum/" title>
                      Impressum
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/kontakt/" title>
                      Kontakt
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/rechtliche-hinweise/" title>
                      Nutzungsbedingungen
                    </a>
                  </li>
                  <li className="leaf">
                    <a href="/datenschutz/" title>
                      Datenschutz
                    </a>
                  </li>
                  <li className="last leaf">
                    <a href="/cookie-richtlinien/" title>
                      Cookie-Richtlinien
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                     IMODIUM®. Wirkstoff: Loperamidhydrochlorid. Über Wirkung und mögliche unerwünschte Wirkungen informieren Gebrauchsinformation, Arzt oder Apotheker.
                  </p>
                  <p>
                    <br />© Johnson &amp; Johnson GmbH 2011-2019. Diese Website
                    wird von der Johnson &amp; Johnson GmbH veröffentlicht, die
                    allein für deren Inhalte verantwortlich ist. Sie ist für
                    Besucher aus Österreich bestimmt.
                    <br />
                    Letzte Aktualisierung am: 23. Dezember 2021, AT-IM-2100045
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n<!--//--><!--[CDATA[//--><!--\nfunction euCookieComplianceLoadScripts() {}\n//--><!--]]-->\n"
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\n<!--//--><!--[CDATA[//--><!--\nvar eu_cookie_compliance_cookie_name = "";\n//--><!--]]-->\n'
          }}
        />
        <script
          type="text/javascript"
          src="/sites/all/modules/contrib/eu_cookie_compliance/js/eu_cookie_compliance.js?qbpk52"
        />
      </div>
    );
  }
}

export default Page;
